import React from "react"

import { Helmet } from "react-helmet"

import "bootstrap/dist/css/bootstrap.min.css"
import "typeface-ubuntu"

export default function Home() {
  return (
    <div
      style={{ background: "#FFFFFF", fontFamily: "ubuntu", fontWeight: 500 }}
      class="vh-100 d-flex justify-content-center align-items-center"
    >
      <Helmet>
        <title>parzu.com</title>
      </Helmet>
      <div>
        <h1 style={{ color: "#EFF2F4" }}>
          parzu<span style={{ color: "#18A4E0" }}>.</span>com
        </h1>
      </div>
    </div>
  )
}
